import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import ManualPaymentFormModel from "./common/manualPaymentFormModel";
import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { FundDto } from "../../app/models/manualPayment/fundDto";
import { ProductModel } from "./common/productModel";
import { PaymentTypes } from "../../app/enums/paymentTypes";
import { ProductDto } from "../../app/models/referenceData/productDto";
import ConfirmationDialog from "../../app/components/ConfirmationDialog";
import { Currency } from "../../app/models/referenceData/currency";

interface Props {
    funds: FundDto[];
    productRows: ProductModel[];
    productTypes: ProductDto[] | null;
    currencies: Currency[] | null;
    deleteProductClicked: (productId: string) => void;
}

interface DeleteProductDto {
    canDelete: boolean;
    productId: string;
}

export default function ProductList({ funds, productRows, productTypes, currencies, deleteProductClicked }: Props) {
    const {
        formField: {
            Products
        }
    } = ManualPaymentFormModel;

    let sum = (a: any) => a.reduce((x: number, y: number) => x + y);

    const { fieldState } = useController({ name: Products.name });

    const [showDeleteProductConfirmationDialog, setShowDeleteProductConfirmationDialog] = useState<DeleteProductDto>({ canDelete: false, productId: '' });

    if (showDeleteProductConfirmationDialog.canDelete === true) {
        return <ConfirmationDialog showDialog={showDeleteProductConfirmationDialog.canDelete}
            title={'Are you sure you want to delete?'}
            subTitle={'Do you want to delete the selected product? On clicking "YES" you will loose the selected product.'}
            yesButtonClick={() => {
                deleteProductClicked(showDeleteProductConfirmationDialog.productId);
                setShowDeleteProductConfirmationDialog({
                    canDelete: false,
                    productId: showDeleteProductConfirmationDialog.productId
                });
            }}
            noButtonClick={() => {
                setShowDeleteProductConfirmationDialog({
                    canDelete: false,
                    productId: showDeleteProductConfirmationDialog.productId
                });
            }}
            showYesNoButtons={true} />
    }

    return <>
        <Grid item xs={12} sx={{ marginTop: '-1%' }}>
            <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'left' }}>Payment items</Typography>
        </Grid>

        <Grid item xs={3}>
            <Typography variant='subtitle2' sx={{ fontWeight: "500", textAlign: 'left' }}>
                <b>Product</b>
            </Typography>
        </Grid>

        <Grid item xs={2}>
            <Typography variant='subtitle2' sx={{ fontWeight: "500", textAlign: 'left' }}>
                <b>Year</b>
            </Typography>
        </Grid>

        <Grid item xs={3}>
            <Typography variant='subtitle2' sx={{ fontWeight: "500", textAlign: 'left' }}>
                <b>Fund</b>
            </Typography>
        </Grid>

        <Grid item xs={2}>
            <Typography variant='subtitle2' sx={{ fontWeight: "500", textAlign: 'left' }}>
                <b>Currency</b>
            </Typography>
        </Grid>

        <Grid item xs={1}>
            <Typography variant='subtitle2' sx={{ fontWeight: "500", textAlign: 'left' }}>
                <b>Amount</b>
            </Typography>
        </Grid>

        <Grid item xs={1}>
            <Typography variant='subtitle2' sx={{ fontWeight: "500", textAlign: 'left' }}>
                <b>Action</b>
            </Typography>
        </Grid>

        {productRows.length > 0 && productRows.map((item: ProductModel) => (
            <React.Fragment key={item.id}>

                <Grid item xs={3}>
                    <Typography variant='caption' sx={{ fontWeight: "500", textAlign: 'left' }}>
                        {productTypes?.find(x => x.id === item.productType)?.code}
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='caption' sx={{ fontWeight: "500", textAlign: 'left' }}>
                        {(item.productType?.toLowerCase() === PaymentTypes.FAM_ANUAL_MEMBERSHIP?.toLowerCase() ||
                            item.productType?.toLowerCase() === PaymentTypes.PCS_ANUAL_MEMBERSHIP?.toLowerCase()) && item.membershipYear !== undefined && item.membershipYear}
                        {(item.productType?.toLowerCase() !== PaymentTypes.FAM_ANUAL_MEMBERSHIP?.toLowerCase() &&
                            item.productType?.toLowerCase() !== PaymentTypes.PCS_ANUAL_MEMBERSHIP?.toLowerCase()) && <>-</>}
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Typography variant='caption' sx={{ fontWeight: "500", textAlign: 'left' }}>
                        {item.productType?.toLowerCase() === PaymentTypes.DONATION?.toLowerCase() && item.fundId !== undefined && funds.find(x => x.id === item.fundId)?.fundname}
                        {item.productType?.toLowerCase() !== PaymentTypes.DONATION?.toLowerCase() && <>-</>}
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography variant='caption' sx={{ fontWeight: "500", textAlign: 'left' }}>
                        {currencies?.find(x => x.id === item.currency)?.name}
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                    <Typography variant='caption' sx={{ fontWeight: "500", textAlign: 'left' }}>
                        {item.amount}
                    </Typography>
                </Grid>

                <Grid item xs={1} sx={{ paddingTop: '20px !important' }}>
                    <Button variant="text" color="primary" onClick={() =>
                        setShowDeleteProductConfirmationDialog({ canDelete: true, productId: (item.id !== null && item.id !== undefined) ? item.id : '' })}>
                        <DeleteIcon />
                    </Button>
                </Grid>

            </React.Fragment >
        ))
        }

        <Grid item xs={7}></Grid>

        <Grid item xs={2}>
            <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'center' }}>Sum</Typography>
        </Grid>

        <Grid item xs={1}>
            <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'center' }}>
                {productRows.length > 0 && currencies?.find(x => x.id === productRows[0].currency)?.code}
            </Typography>
        </Grid>

        <Grid item xs={2}>
            <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'left' }}>
                {productRows.length > 0 && sum(productRows.map(x => Number(x.amount)))}
                {productRows.length === 0 && 0}
            </Typography>
        </Grid>

        <Grid item xs={12}>
            <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error?.message}</FormHelperText>
        </Grid>

        <Grid item xs={12}></Grid>
    </>
}
