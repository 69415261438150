import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useAppSelector } from "../../app/store/configureStore";
import React, { useEffect, useState } from "react";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { useGetCreditSummary } from "../../app/customHooks/useGetCreditSummary";
import { SummaryDto } from "../../app/models/pcs/summaryDto";
import AppInformationText from "../../app/components/AppInformationText";
import cpdCategoriesFormModel from "./cpdCategoriesFormModel";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    gridItem: {
        textAlign: 'center',
        paddingTop: '16px'
    }
}));

export default function SummaryForm() {
    const {
        formField: {
            AccreditedCeActivity,
            ProfessionalDevelopmentPlan,
            PracticeReview,
            WorkbasedLearning,
            OnTarget
        }
    } = cpdCategoriesFormModel;

    const queryParams = new URLSearchParams(window.location.search);
    const personId = queryParams.get("personId");

    const { summaryData, getCreditSummaryStatus } = useAppSelector(state => state.pcs);
    const classes = useStyles();

    useGetCreditSummary(personId);

    const GetSummaryColorCode = (colorName: string) => {
        switch (colorName.toUpperCase()) {
            case "RED":
                return '#d64b5f';
            case "AMBER":
                return "#e46c0a";
            case "GREEN":
                return "#00af66";
            case "WHITE":
                return "#FFFFFF";
            default:
                return "#FFFFFF";
        }
    }

    const [isYearPartialyCompleted, setIsYearPartialyCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (summaryData) {
            summaryData.map((item: SummaryDto[], index) => {
                let isYearPartialyComplete = false;
                if (item[2].text.toLowerCase() === "true" && isYearPartialyComplete === false) {
                    setIsYearPartialyCompleted(true);
                }
            });
        }
    }, [summaryData]);

    if (getCreditSummaryStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (<>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>6 Year Summary</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, padding: 2 }} component={Paper} className={classes.root}>

            <Grid container>
                <Grid item xs={1.5} className={classes.alignCenter}><b>PCS year</b></Grid>
                <Grid item xs={1.5} className={classes.alignCenter}><b>Engaged in practice of medicine</b></Grid>
                <Grid item xs={1.5} className={classes.alignCenter}>
                    <b>{AccreditedCeActivity.label}</b>
                    <br />
                    <AppInformationText information_text={AccreditedCeActivity.informationText} placement="bottom" />
                </Grid>
                <Grid item xs={1.5} className={classes.alignCenter}>
                    <b>{ProfessionalDevelopmentPlan.label}</b>
                    <br />
                    <AppInformationText information_text={ProfessionalDevelopmentPlan.informationText} placement="bottom" />
                </Grid>
                <Grid item xs={1.5} className={classes.alignCenter}><b>{PracticeReview.label}</b>
                    <br />
                    <AppInformationText information_text={PracticeReview.informationText} placement="bottom" />
                </Grid>
                <Grid item xs={1.5} className={classes.alignCenter}><b>{WorkbasedLearning.label}</b>
                    <br />
                    <AppInformationText information_text={WorkbasedLearning.informationText} placement="bottom" />
                </Grid>
                <Grid item xs={1.5} className={classes.alignCenter}><b>{OnTarget.label}</b>
                    <br />
                    <AppInformationText information_text={OnTarget.informationText} placement="bottom" />
                </Grid>
                <Grid item xs={1.5} className={classes.alignCenter}><b>Verification status</b>
                </Grid>

                {summaryData?.map((item: SummaryDto[], index: number) => (
                    <React.Fragment key={index}>

                        <Grid item xs={1.5}>
                            <Grid className={classes.gridItem}>
                                {item[0].text}
                                {item[2].text.toLowerCase() === "true" ? "*" : ""}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5}>
                            <Grid className={classes.gridItem}>{item[1].text}</Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetSummaryColorCode(item[3].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}><b>{item[3].text.split('(')[0]}</b>({item[3].text.split('(')[1]}</Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetSummaryColorCode(item[4].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}><b>{item[4].text.split('(')[0]}</b>({item[4].text.split('(')[1]}</Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetSummaryColorCode(item[5].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}><b>{item[5].text.split('(')[0]}</b>({item[5].text.split('(')[1]}</Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetSummaryColorCode(item[6].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}><b>{item[6].text.split('(')[0]}</b>({item[6].text.split('(')[1]}</Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: GetSummaryColorCode(item[7].status.toUpperCase()),
                                textAlign: 'center', padding: 1
                            }}>{item[7].text}</Grid>
                        </Grid>

                        <Grid item xs={1.5}>
                            <Grid className={classes.gridItem}>{item[8].text}</Grid>
                        </Grid>
                    </React.Fragment>
                ))}

                {summaryData?.length === 0 &&
                    <Grid item xs={12} sx={{ textAlign: 'center', padding: 5 }}>No records found.</Grid>}

                {isYearPartialyCompleted === true &&
                    <Grid item xs={12} sx={{ padding: 2 }}>
                        *Partial Year Completed.
                    </Grid>}

                <Grid item xs={12} sx={{ padding: 2 }}>
                    Note: CPD Category requirements are displayed in brackets.
                </Grid>
            </Grid>
        </Box>
    </>)
}