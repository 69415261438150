import { yupResolver } from "@hookform/resolvers/yup";
import { Stepper, Step, StepLabel, Button, Box, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { getFamDetailsAsync } from "../../app/slices/famSlice";
import { referenceDataAsync, fundsReferenceDataAsync } from "../../app/slices/referenceDataSlice";
import { MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_NEXT, API_REQUEST_FULLFILLED, MESSAGE_SUBMIT } from "../../app/utils/constant";
import { ManualPaymentStepEnum } from "../../app/enums/manualPaymentStepEnum";
import { ManualPaymentValidationSchemas } from "./common/manualPaymentValidationSchemas";
import PaymentDetails from "./PaymentDetails";
import { MapCurrenciesToDropdownItems, MapFundsToDropdownItems, MapProductsToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import { DropdownItem } from "../../app/models/common/dropdownItem";
import ProductDetails from "./ProductDetails";
import { toast } from "react-toastify";
import { submitManualPaymentAsync } from "../../app/slices/manualPaymentSlice";
import { CreateManualPaymentDTO } from "../../app/models/manualPayment/createManualPaymentDTO";
import ManualPaymentFormModel from "./common/manualPaymentFormModel";
import { ManualPaymentProductDTO } from "../../app/models/manualPayment/manualPaymentProductDTO";
import { ProductModel } from "./common/productModel";
import { PaymentTypes } from "../../app/enums/paymentTypes";
import { CurrencyCodeEnum } from "../../app/enums/currencyCodeEnum";
import { EntityTypeEnum } from "../../app/enums/entityTypeEnum";
import { ProductDto } from "../../app/models/referenceData/productDto";
import { v4 as uuid } from 'uuid';
import LoadingComponent from "../../app/components/LoadingComponent";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import SuccessResponseMessage from "./common/SuccessResponseMessage";

export default function ManualPaymentForm() {

    const {
        formField: {
            Currency,
            PaymentDate,
            PaymentMethod,
            Reference,
            PaymentNotes,
            PaymentType,
            Products,
            MembershipYear,
            Fund,
            Amount
        }
    } = ManualPaymentFormModel;

    const steps = [ManualPaymentStepEnum.PaymentDetails, ManualPaymentStepEnum.Product];

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <PaymentDetails currencies={currencyData} paymentMethods={paymentMethods ?? []} />
            case 1:
                return <ProductDetails filteredFunds={fundsData} funds={funds ?? []}
                    productTypes={MapProductsToDropdownItems(productTypeList.filter(x => x.visible === true))}
                    years={years} currencies={currencies ?? []} productRows={productRows}
                    addProductClicked={addProductClicked}
                    deleteProductClicked={deleteProductClicked} />
            default:
                throw new Error('Unknown step');
        }
    }

    const [activeStep, setActiveStep] = useState(0);

    const queryParams = new URLSearchParams(window.location.search);
    const customerId = queryParams.get("customerId");
    const entityType = queryParams.get("entityType");

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(ManualPaymentValidationSchemas[activeStep])
    });

    const { trigger, getValues, setValue, watch, formState: { isDirty } } = methods;

    const dispatch = useAppDispatch();

    const { productTypes, paymentMethods, funds, currencies } = useAppSelector(state => state.referenceData);
    const { submitManualPaymentStatus } = useAppSelector(state => state.manualPayment);
    const { famDetails, famDetailsStatus } = useAppSelector(state => state.famDetails);

    const [pageLoading, setPageLoading] = useState(false);

    const [fundsData, setFundsData] = useState<DropdownItem[]>([]);
    const [productRows, setProductRows] = useState<ProductModel[]>([]);
    const [currencyData, setCurrencyData] = useState<DropdownItem[]>([]);
    const [productTypeList, setProductTypeList] = useState<ProductDto[]>([]);

    useEffect(() => {
        if ((productTypes === null || productTypes?.length === 0) &&
            (paymentMethods === null || paymentMethods?.length === 0) &&
            (funds === null || funds?.length === 0) &&
            (currencies === null || funds?.length === 0) &&
            famDetails === null && customerId !== null) {
            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(referenceDataAsync()),
                dispatch(fundsReferenceDataAsync()),
                dispatch(getFamDetailsAsync(customerId))
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [dispatch, currencies, productTypes, paymentMethods, funds, famDetails, customerId]);

    const populateProductTypes = () => {
        if (productTypes !== null) {
            let currencyCode = currencies?.find(x => x.id === getValues(Currency.name))?.code;
            if (famDetails?.famDetailsId === null
                || (famDetails?.famDetailsId !== null && currencyCode !== CurrencyCodeEnum.EUR)
                || entityType?.toLowerCase() === EntityTypeEnum.ACCOUNT.toLowerCase()) {
                setProductTypeList(productTypes?.filter(x => x.code !== PaymentTypes.FAM_ANUAL_MEMBERSHIP));
            }
            else if (famDetails?.famDetailsId !== null && currencyCode === CurrencyCodeEnum.EUR) {
                setProductTypeList(productTypes);
            }
        }
    }

    useEffect(() => {
        populateProductTypes();
    }, [famDetails, productTypes, currencies, entityType, getValues]);

    useEffect(() => {
        if ((currencies && currencies?.length > 0) &&
            (funds && funds?.length > 0)) {
            let currencyList = currencies?.filter(x => funds?.some(y => x.id === y.currencyId));

            setCurrencyData(MapCurrenciesToDropdownItems(currencyList));
        }
    }, [currencies, funds]);

    const populateFunds = () => {
        let fundList = funds?.filter(x => x.currencyId === getValues(Currency.name));

        setFundsData(MapFundsToDropdownItems(fundList));
    }

    useEffect(() => {
        if (funds && funds?.length > 0) {
            populateFunds();
        }
    }, [funds, getValues]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (currencyData.length > 0 && funds && funds?.length > 0) {
                if (name === Currency.name && type === 'change') {
                    populateFunds();
                    populateProductTypes();
                    setProductRows([]);
                    setValue(PaymentType.name, undefined);
                }
            }
        });
    }, [funds, currencyData, PaymentType.name, Currency.name, watch, famDetails, productTypes, currencies, entityType, getValues]);

    const [years, setYears] = useState<DropdownItem[]>([
        { id: (new Date().getFullYear() - 1).toString(), value: (new Date().getFullYear() - 1).toString() },
        { id: (new Date().getFullYear()).toString(), value: (new Date().getFullYear()).toString() },
        { id: (new Date().getFullYear() + 1).toString(), value: (new Date().getFullYear() + 1).toString() }]);

    const addProductClicked = (productItem: ProductModel) => {
        productItem.id = uuid();
        productItem.productId = productTypes?.find(x => x.code === getValues(PaymentType.name))?.id;

        setProductRows([...productRows, productItem]);
        setValue(Products.name, [...productRows, productItem]);
        setValue(MembershipYear.name, '');
        setValue(Fund.name, '');
        setValue(Amount.name, '');

        getValues(PaymentType.name) === PaymentTypes.FAM_ANUAL_MEMBERSHIP && setYears(years.filter(x => x.id !== productItem.membershipYear));
        getValues(PaymentType.name) === PaymentTypes.DONATION && setFundsData(fundsData.filter(x => x.id !== productItem.fundId));
        trigger();
    }

    const deleteProductClicked = (item: string) => {
        let excludedCurrentMembershipYear = productRows.filter(x => x.id !== item);
        setProductRows(excludedCurrentMembershipYear);
        setValue(Products.name, excludedCurrentMembershipYear);

        let productCode = productTypes?.find(x => x.id === productRows.find(x => x.id === item)?.productId)?.code;
        if (productCode === PaymentTypes.FAM_ANUAL_MEMBERSHIP) {
            let year: string = productRows.find(x => x.id === item)?.membershipYear ?? '';
            setYears([...years, { id: year, value: year }]);
        }
        else if (productCode === PaymentTypes.DONATION) {
            let fundId = productRows.find(x => x.id === item)?.fundId ?? '';
            let fundValue = funds?.find(x => x.id === fundId)?.fundname ?? '';
            let fundCode = funds?.find(x => x.id === fundId)?.code ?? '';

            setFundsData([...fundsData, { id: fundId, value: fundValue, code: fundCode }]);
        }
    }

    const mapToPaymentProducts = () => {
        let productPrices: ManualPaymentProductDTO[] = [];

        productRows?.map((item: ProductModel) => {
            return productPrices.push({
                cost: item.amount,
                productId: item.productId ?? '',
                year: item.membershipYear ?? '',
                fundId: item.fundId ?? ''
            });
        });

        return productPrices;
    }

    const mapToPaymentInfo = () => {
        let paymentObj: CreateManualPaymentDTO = {
            personId: customerId,
            EntityType: entityType,
            paymentMethodId: getValues(PaymentMethod.name),
            reference: getValues(Reference.name),
            note: getValues(PaymentNotes.name),
            paymentDate: getValues(PaymentDate.name),
            paymentProducts: mapToPaymentProducts(),
            currencyId: getValues(Currency.name)
        }

        return paymentObj;
    }

    const saveManualPayment = async () => {
        dispatch(submitManualPaymentAsync(mapToPaymentInfo())).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
                toast.success("Payment submitted successfully.");
            }
        });
    }

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if (isDirty === true) {
                switch (activeStep) {
                    case 0:
                        setActiveStep(prevActiveStep => prevActiveStep + 1);
                        break;
                    case 1:
                        saveManualPayment();
                        break;
                    default:
                        return "not a valid step";
                }
            }
            else if (isDirty === false) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        }
    }

    async function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    const [width, setWidth] = useState<number>(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    if (submitManualPaymentStatus === ApiRequestStatus.Fulfilled) {
        return <SuccessResponseMessage header={'Thank you'} body={`Payment created successfully.`} />
    }

    if (pageLoading === true || submitManualPaymentStatus === ApiRequestStatus.Pending ||
        famDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message='Processing details...' />
    }

    return (
        <FormProvider {...methods}>
            <br></br>
            <br></br>

            <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <br></br>

            {activeStep !== 0 && (
                <Button variant="contained" onClick={_handleBack}>
                    {MESSAGE_BACK_TO_PREVIOUS_STEP}
                </Button>
            )}

            {activeStep !== steps.length &&
                <>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid container rowSpacing={4}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                                {activeStep < steps.length &&
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        {activeStep === 0 ? MESSAGE_NEXT : MESSAGE_SUBMIT}
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </Box>
                </>
            }
        </FormProvider >
    );
}
